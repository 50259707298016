<template>
  <div class="sect_cont">
    <div class="sect_type_3">
      <ValidationObserver ref="obServer">
        <form @submit.prevent="modify">
          <!-- <div class="title">비밀번호</div> -->
          <table summary="">
            <colgroup>
              <col width="*" />
            </colgroup>
            <tbody>
              <tr>
                <td>
                  <ValidationProvider
                    :rules="{
                      required: true,
                      min_length: 8,
                    }"
                    v-slot="{ errors }"
                    name="현재 비밀번호"
                  >
                    <input
                      type="password"
                      name="password"
                      maxlength="20"
                      v-model="password"
                      placeholder="현재 비밀번호"
                      @keypress.enter="modify"
                    />
                    <p v-if="errors[0]" class="error-txt">{{ errors[0] }}</p>
                  </ValidationProvider>
                </td>
              </tr>
              <tr>
                <td>
                  <ValidationProvider
                    :rules="{
                      required: true,
                      min_length: 8,
                    }"
                    v-slot="{ errors }"
                    name="새 비밀번호"
                    vid="new_password"
                  >
                    <input
                      type="password"
                      name="new_password"
                      maxlength="20"
                      v-model="new_password"
                      placeholder="새 비밀번호"
                      @keypress.enter="modify"
                    />
                    <p v-if="errors[0]" class="error-txt">{{ errors[0] }}</p>
                  </ValidationProvider>
                </td>
              </tr>
              <tr>
                <td>
                  <ValidationProvider
                    :rules="{
                      required: true,
                      min_length: 8,
                      new_confirmed: 'new_password',
                    }"
                    v-slot="{ errors }"
                    name="새 비밀번호 확인"
                  >
                    <input
                      type="password"
                      name="new_re_password"
                      maxlength="20"
                      v-model="new_re_password"
                      placeholder="새 비밀번호 확인"
                      @keypress.enter="modify"
                    />
                    <p v-if="errors[0]" class="error-txt">{{ errors[0] }}</p>
                  </ValidationProvider>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </ValidationObserver>
      <div class="btn">
        <router-link to="" @click.native="modify" class="black"
          >수정 완료</router-link
        ><router-link to="" @click.native="clear" class="gray"
          >취소</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    myInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      password: null,
      new_password: null,
      new_re_password: null,
    };
  },
  methods: {
    clear() {
      this.password = null;
      this.new_password = null;
      this.new_re_password = null;
    },
    async modify() {
      const validate = await this.$refs.obServer.validate();
      if (validate) {
        await this.$store.dispatch("mypage/chagePassword", {
          password: this.password,
          new_password: this.new_password,
          new_re_password: this.new_re_password,
          cellphone: this.myInfo.cellphone,
        });
        this.$toast.default(this.$store.state.mypage.msg);
        if (this.$store.state.mypage.result) {
          this.$store.dispatch("member/logOut");
          this.$router.push("/member/login");
        }
      }
    },
  },
};
</script>

<style></style>
