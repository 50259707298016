<template>
  <div class="sect_cont">
    <ul class="sect_tab2" ref="sect_tab">
      <li class="on" ref="normal">
        <router-link to="" @click.native="view('normal')">일반</router-link>
      </li>
      <li v-if="myInfo.is_reviewer == 'Y'" ref="reviewer">
        <router-link to="" @click.native="view('reviewer')">리뷰어</router-link>
      </li>
      <li v-if="myInfo.is_seller == 'Y'" ref="seller">
        <router-link to="" @click.native="view('seller')">판매자</router-link>
      </li>
      <!-- <li v-if="myInfo.is_exchange == 'Y'" ref="exchange">
        <router-link to="" @click.native="view('exchange')" >거래소</router-link>
      </li> -->
    </ul>
    <div class="sect_type_1">
      <div class="title">프로필 사진</div>
      <div class="cont">
        <div class="left">
          <img :src="myImg" @error="replaceImg" alt="" v-if="channel.normal" />
          <img
            :src="myReviewerImg"
            @error="replaceImg"
            alt=""
            v-if="channel.reviewer"
          />
          <img
            :src="mySellerImg"
            @error="replaceImg"
            alt=""
            v-if="channel.seller"
          />
          <img
            :src="myExchangeImg"
            @error="replaceImg"
            alt=""
            v-if="channel.exchange"
          />
          <input
            type="file"
            name="filedata"
            style="display: none"
            accept="image/*"
            ref="upload_Img"
            @change="change_img"
          />
        </div>
        <div class="right">
          <router-link to="" class="black" @click.native="regist_img"
            >사진등록</router-link
          ><router-link to="" class="gray" @click.native="delete_img"
            >사진삭제</router-link
          >
        </div>
      </div>
    </div>
    <div class="sect_type_2" v-if="channel.normal">
      <div class="title">닉네임 변경</div>
      <table summary="">
        <colgroup>
          <col width="80px" />
          <col width="*" />
          <col width="85px" />
        </colgroup>
        <tbody>
          <tr>
            <td class="tit">{{ userTypeTit }}</td>
            <td>
              <input
                type="text"
                name="nickname"
                value=""
                ref="nickname"
                maxlength="10"
                v-model="nickname"
              />
            </td>
            <td>
              <router-link to="" @click.native="dupleNickname()"
                >중복확인</router-link
              >
            </td>
          </tr>
        </tbody>
      </table>
      <div class="btn">
        <router-link to="" @click.native="change_nick" class="black"
          >수정 완료</router-link
        ><router-link to="" @click.native="cancel_nick" class="gray"
          >취소</router-link
        >
      </div>
    </div>
    <div class="sect_type_2" v-if="channel.seller">
      <div class="title">상호명 변경</div>
      <div class="change_info">
        <div class="top">
          <ul>
            <li>
              회사의 상호명은 임의로 변경할 수 없습니다. 변경하려 는 상호명은
              적법한 절차를 따라 관할 등기소에 등록된 상호명이어야 합니다.
              상호명을 변경하려면 아래의 서류를 작성해 제출해주세요.
            </li>
          </ul>
        </div>
        <div class="privacy">
          <div class="check">
            <label
              ><input type="checkbox" name="" v-model="checkPrivarcy" />개인정보
              수집이용 동의 (필수)</label
            ><router-link to="" @click.native="open('privacy')"
              ><img src="@/assets/images/sub/read_agree.png" alt=""
            /></router-link>
          </div>
          <div class="check">
            <label
              ><input type="checkbox" name="" v-model="checkService" />개인정보
              제공 동의 (필수) </label
            ><router-link to="" @click.native="open('service')"
              ><img src="@/assets/images/sub/read_agree.png" alt=""
            /></router-link>
          </div>
        </div>
      </div>
      <ValidationObserver ref="obServer">
        <form id="modifyApply" @submit.prevent="modifyApply">
          <table summary="">
            <colgroup>
              <col width="120px" />
              <col width="*" />
            </colgroup>
            <tbody>
              <tr>
                <td class="tit">상호명</td>
              </tr>
              <tr>
                <td colspan="2">
                  <ValidationProvider
                    :rules="{
                      required: true,
                    }"
                    v-slot="{ errors }"
                    name="상호명"
                  >
                    <input
                      class="seller_input"
                      type="text"
                      name=""
                      maxlength="20"
                      v-model="providerName"
                    />
                    <p v-if="errors[0]" class="error-txt">{{ errors[0] }}</p>
                  </ValidationProvider>
                </td>
              </tr>
              <tr>
                <td class="tit">회사 연락처</td>
              </tr>
              <tr>
                <td colspan="2">
                  <ValidationProvider
                    :rules="{
                      required: true,
                      phone: true,
                    }"
                    v-slot="{ errors }"
                    name="회사 연락처"
                  >
                    <input
                      class="seller_input"
                      type="text"
                      name=""
                      maxlength="11"
                      v-model="providerPhoneCk"
                    />
                    <p v-if="errors[0]" class="error-txt">{{ errors[0] }}</p>
                  </ValidationProvider>
                </td>
              </tr>
              <tr>
                <td class="tit">담당자 이름</td>
              </tr>
              <tr>
                <td colspan="2">
                  <ValidationProvider
                    :rules="{
                      required: true,
                    }"
                    v-slot="{ errors }"
                    name="담당자 이름"
                  >
                    <input
                      type="text"
                      class="seller_input"
                      name=""
                      maxlength="20"
                      v-model="name"
                    />
                    <p v-if="errors[0]" class="error-txt">{{ errors[0] }}</p>
                  </ValidationProvider>
                </td>
              </tr>
              <tr>
                <td class="tit">담당자 연락처</td>
              </tr>
              <tr>
                <td colspan="2">
                  <ValidationProvider
                    :rules="{
                      required: true,
                      cellphone: true,
                    }"
                    v-slot="{ errors }"
                    name="담당자 연락처"
                    maxlength="11"
                  >
                    <input
                      type="text"
                      class="seller_input"
                      name=""
                      v-model="cellphoneCk"
                    />
                    <p v-if="errors[0]" class="error-txt">{{ errors[0] }}</p>
                  </ValidationProvider>
                </td>
              </tr>
              <tr>
                <td class="tit">담당자 이메일</td>
              </tr>
              <tr>
                <td colspan="2">
                  <ValidationProvider
                    :rules="{
                      required: true,
                      email: true,
                    }"
                    v-slot="{ errors }"
                    name="담당자 이메일"
                  >
                    <input
                      type="text"
                      class="seller_input"
                      name=""
                      maxlength="64"
                      v-model="email"
                    />
                    <p v-if="errors[0]" class="error-txt">{{ errors[0] }}</p>
                  </ValidationProvider>
                </td>
              </tr>
              <tr>
                <td class="tit">사업자 번호</td>
              </tr>
              <tr>
                <td colspan="2">
                  <ValidationProvider
                    :rules="{
                      required: true,
                      sellingLicense: true,
                    }"
                    v-slot="{ errors }"
                    name="사업자 번호"
                  >
                    <input
                      type="text"
                      class="seller_input"
                      name=""
                      v-model="businessNumberCk"
                    />
                    <p v-if="errors[0]" class="error-txt">{{ errors[0] }}</p>
                  </ValidationProvider>
                </td>
              </tr>
              <tr>
                <td class="tit">통신판매 신고번호</td>
              </tr>
              <tr>
                <td colspan="2">
                  <ValidationProvider
                    :rules="{
                      required: true,
                    }"
                    v-slot="{ errors }"
                    name="통신판매 신고번호"
                  >
                    <input
                      type="text"
                      class="seller_input"
                      name=""
                      v-model="sellingLicense"
                    />
                    <p v-if="errors[0]" class="error-txt">{{ errors[0] }}</p>
                  </ValidationProvider>
                </td>
              </tr>
              <tr>
                <td class="tit">사업자등록증<br />사본 첨부</td>
                <td class="file_btn">
                  <router-link
                    to=""
                    @click.native="regist_bizImg"
                    id="regist_biz_img"
                    >파일 첨부</router-link
                  >
                  <input
                    type="file"
                    name="business_doc_file"
                    style="display: none"
                    accept="image/*"
                    ref="business_doc_file"
                    @change="set_filename"
                  />
                  <router-link
                    to=""
                    id="view_biz_img"
                    @click.native="imgOpen"
                    v-if="myInfo.info_file"
                    >등록증 보기</router-link
                  >
                  <p ref="file_name" class="error-txt"></p>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="btn">
            <router-link to="" @click.native="modifyApply" class="black"
              >변경 신청</router-link
            ><router-link to="" @click.native="clearModifyApply" class="gray"
              >취소</router-link
            >
          </div>
          <div class="sel_cate_before">
            <div class="title">대표카테고리 등록/변경</div>
            <div class="list" ref="list">
              <div
                :class="{ on: category.category_code === categoryCode }"
                class="box"
                v-for="(category, index) in categories"
                :key="category.category_code"
              >
                <router-link
                  to=""
                  @click.native="selectCategory(category.category_code, index)"
                  >{{ category.title }}</router-link
                >
              </div>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
    <ImageModal
      :img="`/data/provider/${myInfo.info_file}`"
      v-if="isOpen"
      @imgOpen="imgOpen"
    />
    <SellerProvidePrivacy
      class="modals"
      v-if="isPrivacy"
      :isModals="true"
      @open="open"
    />
    <SellerAgreePrivacy
      class="modals"
      v-if="isService"
      :isModals="true"
      @open="open"
    />
  </div>
</template>

<script>
import ImageModal from "@/components/util/ImageModal";
import SellerProvidePrivacy from "@/components/policy/SellerProvidePrivacy";
import SellerAgreePrivacy from "@/components/policy/SellerAgreePrivacy";
export default {
  props: {
    myInfo: {
      type: Object,
      default: () => ({}),
    },
    msg: {
      type: String,
      default: null,
    },
    myChannel: {
      type: Object,
      dafault: () => ({}),
    },
  },
  data() {
    return {
      isOpen: false,
      userType: "normal",
      userTypeName: "일반회원",
      userTypeTit: null,
      nickname: null,
      image: null,
      channel: {
        normal: true,
        reviewer: false,
        seller: false,
        exchange: false,
      },
      categories: [],
      providerName: this.myInfo.seller_nick || "",
      providerPhone: this.myInfo.provider_phone
        ? this.myInfo.provider_phone.replace(/-/gi, "")
        : "",
      name: this.myInfo.seller_name || "",
      cellphone: this.myInfo.seller_cellphone
        ? this.myInfo.seller_cellphone.replace(/-/gi, "")
        : "",
      email: this.myInfo.seller_email || "",
      businessNumber: this.myInfo.business_number
        ? this.myInfo.business_number.replace(/-/gi, "")
        : "",
      sellingLicense: this.myInfo.selling_license || "",
      categoryCode: this.myInfo.category_code || "",
      isService: false,
      isPrivacy: false,
      checkPrivarcy: false,
      checkService: false,
    };
  },
  mounted() {
    this.nickname = this.myInfo.nickname;
    this.view(
      Object.keys(this.myChannel).find((ch) => this.myChannel[ch] === true)
    );
    this.getMainCategory();
  },
  computed: {
    businessNumberCk: {
      get() {
        return this.businessNumber;
      },
      set(businessNumber) {
        this.businessNumber = businessNumber.replace(/[^0-9]/g, "");
      },
    },
    cellphoneCk: {
      get() {
        return this.cellphone;
      },
      set(cellphone) {
        this.cellphone = cellphone.replace(/[^0-9]/g, "");
      },
    },
    providerPhoneCk: {
      get() {
        return this.providerPhone;
      },
      set(providerPhone) {
        this.providerPhone = providerPhone.replace(/[^0-9]/g, "");
      },
    },
    myImg() {
      return this.myInfo.member_img
        ? `${process.env.VUE_APP_API_URL}${this.myInfo.member_img}`
        : "";
    },
    myReviewerImg() {
      return this.myInfo.reviewer_img
        ? `${process.env.VUE_APP_API_URL}${this.myInfo.reviewer_img}`
        : "";
    },
    mySellerImg() {
      return this.myInfo.seller_img
        ? `${process.env.VUE_APP_API_URL}${this.myInfo.seller_img}`
        : "";
    },
    myExchangeImg() {
      return this.myInfo.exchange_img
        ? `${process.env.VUE_APP_API_URL}${this.myInfo.exchange_img}`
        : "";
    },
  },
  components: {
    ImageModal,
    SellerAgreePrivacy,
    SellerProvidePrivacy,
  },
  methods: {
    open(type) {
      document.querySelector("html").style.overflow = "hidden";
      switch (type) {
        case "service":
          this.isPrivacy = false;
          this.isService = true;
          break;
        case "privacy":
          this.isService = false;
          this.isPrivacy = true;
          break;
        default:
          document.querySelector("html").style.overflow = "";
          this.isPrivacy = false;
          this.isService = false;
          break;
      }
      console.log(document.querySelector("html").style.overflow);
    },
    replaceImg(e) {
      e.target.src = require("@/assets/images/noimage.gif");
    },
    imgOpen() {
      this.isOpen = !this.isOpen;
    },
    view(type) {
      this.userType = type;
      const sect_tab_children = [...this.$refs.sect_tab.children]; //바로 아래 자식들은 배열로 구조할당

      if (sect_tab_children.length > 1) {
        sect_tab_children.forEach((node) => {
          node.classList.remove("on");
        });
      }

      switch (type) {
        case "normal":
          this.$refs.normal.classList.add("on");
          this.set_channel("normal");
          this.userTypeName = "일반회원";
          this.userTypeTit = this.userTypeName;
          this.nickname = this.myInfo.nickname;
          break;

        case "reviewer":
          this.$refs.reviewer.classList.add("on");
          this.set_channel("reviewer");
          this.userTypeName = "리뷰어";
          this.userTypeTit = this.userTypeName;
          this.nickname = this.myInfo.reviewer_nick;
          break;

        case "seller":
          this.$refs.seller.classList.add("on");
          this.set_channel("seller");
          break;

        // case "exchange":
        //   this.$refs.exchange.classList.add("on");
        //   this.set_channel("exchange");
        //   this.userTypeName = "리뷰거래소";
        //   this.userTypeTit = this.userTypeName;
        //   this.nickname = this.myInfo.exchange_nick;
        //   break;

        default:
          sect_tab_children[0].classList.add("on");
          this.set_channel("normal");
          this.userTypeName = "일반회원";
          this.userTypeTit = this.userTypeName;
          this.nickname = this.myInfo.nickname;
          break;
      }
    },
    set_channel(key) {
      Object.keys(this.channel).forEach((k) => (this.channel[k] = false));
      this.channel[key] = true;
    },
    regist_img() {
      this.$refs.upload_Img.click();
    },
    change_img() {
      this.image = this.$refs.upload_Img.files[0];

      let ext = this.image.name.split(".").pop().toLowerCase();
      const allow_ext = ["jpg", "png", "jpeg", "gif"];
      const ext_check = allow_ext.some((item) => item === ext);
      if (!ext_check) {
        this.$toast.default("허용되지 않는 파일 입니다.");
        this.$refs.upload_Img.value = null;
        return false;
      }
      if (this.image) {
        this.$confirm(
          `${this.userTypeName} 채널  프로필 이미지를 변경 하시겠습니까? `
        )
          .then(async () => {
            let data = new FormData();
            data.append("filedata", this.image);
            data.append("channel", this.userType);
            await this.$store.dispatch("mypage/setUserImage", data);
            this.$refs.upload_Img.value = null;
            this.$toast.default(this.msg);
          })
          .catch(() => {
            this.$refs.upload_Img.value = null;
            return false;
          });
      }
    },
    delete_img() {
      this.$confirm(
        `${this.userTypeName} 채널  프로필 이미지를 삭제 하시겠습니까? `
      )
        .then(async () => {
          const data = {
            channel: this.userType,
          };
          await this.$store.dispatch("mypage/deleteUserImage", data);
          this.$toast.default(this.msg);
        })
        .catch(() => {
          return false;
        });
    },
    async dupleNickname(check = null) {
      const nickname = this.nickname;
      const phoenReg = /[^a-z0-9가-힝\-_]/gi;
      let isSame = false;

      switch (this.userType) {
        case "normal":
          if (nickname === this.myInfo.nickname) {
            isSame = true;
          }
          break;
        case "reviewer":
          if (nickname === this.myInfo.reviewer_nick) {
            isSame = true;
          }
          break;
        case "seller":
          if (nickname === this.myInfo.seller_nick) {
            isSame = true;
          }
          break;
        case "exchange":
          if (nickname === this.myInfo.exchange_nick) {
            isSame = true;
          }
          break;
      }
      if (isSame) {
        this.$toast.default("현재 닉네임과 동일 합니다.");
        return false;
      }
      if (phoenReg.test(nickname)) {
        this.$toast.default(
          "닉네임은 '영문,한글,숫자,-,_' 만 사용할 수 있습니다."
        );
        return false;
      }

      //check 현재 닉네임만 비교할때
      if (check) {
        return true;
      }

      await this.$store.dispatch("mypage/dupleNickname", {
        nickname,
        channel: this.userType,
      });
      this.$toast.default(this.msg);
    },
    async change_nick() {
      if (!(await this.dupleNickname(true))) {
        return false;
      }
      const nickname = this.nickname;
      await this.$store.dispatch("mypage/changeNickname", {
        nickname,
        channel: this.userType,
      });
      switch (this.userType) {
        case "normal":
          this.nickname = this.myInfo.nickname;
          break;
        case "reviewer":
          this.nickname = this.myInfo.reviewer_nick;
          break;
        case "seller":
          this.nickname = this.myInfo.seller_nick;

          break;
        case "exchange":
          this.nickname = this.myInfo.exchange_nick;
          break;
      }
      this.$toast.default(this.msg);
    },
    regist_bizImg() {
      this.$refs.business_doc_file.click();
    },
    selectCategory(code, index) {
      const boxs = [...this.$refs.list.children];
      if (this.myInfo.is_seller === "Y") {
        this.$confirm("대표 카테고리를 변경하시겠습니까?")
          .then(async () => {
            this.$store.dispatch("mypage/changeMainCategory", { code });
            if (this.$store.state.mypage.result) {
              if (boxs.length > 0) {
                boxs.forEach((box) => {
                  box.classList.remove("on");
                });
                boxs[index].classList.add("on");
                this.categoryCode = code;
              }
            } else {
              this.$toast.default(this.$store.state.mypage.msg);
            }
          })
          .catch(() => {
            return false;
          });
      } else {
        if (boxs.length > 0) {
          boxs.forEach((box) => {
            box.classList.remove("on");
          });
          boxs[index].classList.add("on");
          this.categoryCode = code;
        }
      }
    },
    async getMainCategory() {
      await this.$store.dispatch("shop/getMainCategory");
      this.categories = this.$store.state.shop.mainCategory;
    },
    set_filename() {
      this.$refs.file_name.textContent = "";
      if (this.$refs.business_doc_file.files[0]) {
        this.$refs.file_name.textContent =
          this.$refs.business_doc_file.files[0].name;
      }
    },

    async modifyApply() {
      const validate = await this.$refs.obServer.validate();
      if (!this.checkPrivarcy) {
        this.$toast.default("개인정보 수집이용에 동의해주세요.");
        return false;
      }
      if (!this.checkService) {
        this.$toast.default("개인정보 제공에 동의해주세요.");
        return false;
      }
      if (validate) {
        this.image = this.$refs.business_doc_file.files[0];
        if (!this.image) {
          this.$toast.default("사업자등록증 사본 이미지를 첨부 해주세요");
          return false;
        }
        let ext = this.image.name.split(".").pop().toLowerCase();
        const allow_ext = ["jpg", "png", "jpeg", "gif"];
        const ext_check = allow_ext.some((item) => item === ext);
        if (!ext_check) {
          this.$toast.default("허용되지 않는 파일 입니다.");
          this.$refs.business_doc_file.value = null;
          return false;
        }
        if (!this.categoryCode) {
          this.$toast.default("대표 카테고리를 선택해 주세요.");
          return false;
        }
        let data = new FormData();
        data.append("provider_name", this.providerName);
        data.append("provider_phone", this.providerPhone);
        data.append("name", this.name);
        data.append("cellphone", this.cellphone);
        data.append("email", this.email);
        data.append("business_number", this.businessNumber);
        data.append("selling_license", this.sellingLicense);
        data.append("category_code", this.categoryCode);
        data.append("filedata", this.image);
        data.append("apply_result", "change"); // 상호변경 요청
        data.append("provider_seq", this.myInfo.provider_seq); // 상호변경 요청
        this.$confirm("상호명 변경 신청을 진행 하시겠습니까?")
          .then(async () => {
            await this.$store.dispatch("mypage/sellerApply", data);
            this.$toast.default(this.$store.state.mypage.msg);
          })
          .catch(() => {
            return false;
          });
      }
    },
    clearModifyApply() {
      this.providerName = this.myInfo.seller_nick || "";
      this.providerPhone = this.myInfo.provider_phone
        ? this.myInfo.provider_phone.replace(/-/gi, "")
        : "";
      this.name = this.myInfo.seller_name || "";
      this.cellphone = this.myInfo.seller_cellphone
        ? this.myInfo.seller_cellphone.replace(/-/gi, "")
        : "";
      this.email = this.myInfo.seller_email || "";
      this.businessNumber = this.myInfo.business_number
        ? this.myInfo.business_number.replace(/-/gi, "")
        : "";
      this.sellingLicense = this.myInfo.selling_license || "";
      this.categoryCode = this.myInfo.category_code || "";
      this.$refs.file_name.textContent = "";
    },
    cancel_nick() {
      const nickname = this.$refs.nickname;
      if (!nickname) {
        return true;
      }
      switch (this.userType) {
        case "normal":
          nickname.value = this.myInfo.nickname;
          break;
        case "reviewer":
          nickname.value = this.myInfo.reviewer_nick;
          break;
        case "seller":
          nickname.value = this.myInfo.seller_nick;
          break;
        case "exchange":
          nickname.value = this.myInfo.exchange_nick;
          break;
      }
    },
  },
  beforeDestroy() {
    document.querySelector("html").style.overflow = "";
  },
};
</script>

<style lang="scss" scoped>
#regist_biz_img {
  padding: 0 5px;
  margin-right: 10px;
}
#view_biz_img {
  padding: 0 5px;
  background: #484848;
  color: #fff;
}
.rn_my_box_19 .top .box .sect_cont .sect_type_1 .cont .left {
  border-radius: 0;
}
.rn_my_box_19 .top .box .sect_cont .sect_type_2 {
  table {
    tr {
      .seller_input {
        width: 100%;
      }
      .file_btn {
        text-align: right;
      }
    }
  }
  .sel_cate_before {
    margin: 20px 0;
    .list {
      display: flex;
      flex-wrap: wrap;
      justify-content: left;
      .box {
        width: calc((100% - 30px) / 4);
        height: 35px;
        background: #d3d3d3;
        margin-right: 10px;
        text-align: center;
        color: #484848;
        font-size: 13px;
        line-height: 35px;
        font-weight: 600;
        &.on {
          background-color: #484848;
          a {
            color: #fff;
          }
        }
        &:nth-child(1) {
          margin: 10px 10px 0 0;
        }
        &:nth-child(4n) {
          margin-right: 0px;
        }
      }
    }
  }
  .change_info {
    width: 100%;
    box-sizing: border-box;
    .top {
      width: 100%;
      padding-bottom: 10px;
      border-bottom: 1px solid #000;
      ul {
        li {
          width: 100%;
          line-height: 16px;
          font-size: 12px;
          margin-top: 5px;
          padding-left: 6px;
          box-sizing: border-box;
          position: relative;
          &::before {
            content: "·";
            width: 6px;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }
    }
  }
  .privacy {
    padding-top: 5px;
    border-bottom: 1px solid #000;
    padding-bottom: 5px;
    margin-bottom: 10px;
    .check {
      input {
        margin-right: 5px;
      }
      a {
        float: right;
      }
      padding: 5px;
    }
  }
}
.modals {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: #fff;
  overflow: scroll;
  height: calc(100% - 40px);
}
</style>
