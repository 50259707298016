<template>
  <div class="sect_cont">
    <div class="sect_type_2" style="padding-bottom: 15px">
      <div class="title">휴대전화</div>
      <table summary="">
        <colgroup>
          <col width="80px" />
          <col width="*" />
          <col width="85px" />
        </colgroup>
        <tbody>
          <tr>
            <td class="tit">현재</td>
            <td colspan="2">+82 {{ myInfo.cellphone }}</td>
          </tr>
          <tr>
            <td class="tit">변경</td>
            <td :style="{ position: 'relative' }">
              <input
                type="text"
                name="cellphone"
                placeholder="휴대폰번호 입력"
                maxlength="11"
                v-model="cellphoneCk"
                @keypress.enter="getAuthNumber"
              />
              <div class="timer" v-if="isAuthNumber">
                <span ref="timer_min">2</span>
                <span>:</span>
                <span ref="timer_sec">59</span>
              </div>
            </td>
            <td>
              <router-link to="" @click.native="getAuthNumber"
                >인증번호 전송</router-link
              >
            </td>
          </tr>
          <tr>
            <td class="tit">인증번호</td>
            <td>
              <input
                type="text"
                name="auth_number"
                placeholder="인증번호 입력"
                maxlength="6"
                v-model="authNumberCk"
                @keypress.enter="changePhoneNumber"
              />
            </td>
            <td></td>
          </tr>
          <tr>
            <td class="tit">SMS 동의</td>
            <td>
              <input
                type="checkbox"
                name=""
                id="sms"
                @change="check(true)"
                ref="sms"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div class="btn">
        <router-link to="" class="black" @click.native="changePhoneNumber"
          >수정 완료</router-link
        ><router-link to="" class="gray" @click.native="clear()"
          >취소</router-link
        >
      </div>
    </div>
    <div class="sect_type_2">
      <div class="title">이메일</div>
      <table summary="">
        <colgroup>
          <col width="80px" />
          <col width="*" />
          <col width="85px" />
        </colgroup>
        <tbody>
          <tr>
            <td class="tit">현재</td>
            <td colspan="2">{{ myInfo.email }}</td>
          </tr>
          <tr>
            <td class="tit">변경</td>
            <td>
              <input
                type="text"
                name=""
                maxlength="64"
                v-model="email"
                @keypress.enter="authEmail"
              />
            </td>
            <td>
              <router-link to="" @click.native="authEmail">인증</router-link>
            </td>
          </tr>
          <tr>
            <td class="tit">수신 동의</td>
            <td>
              <input
                type="checkbox"
                name=""
                id="mailing"
                @change="check()"
                ref="mailing"
              />
            </td>
          </tr>
          <tr class="txt">
            <td colspan="3">
              램에서 발송된 메일을 열어 [이메일 인증하기] 버튼을 누른 후 아래
              [수정 완료]를 눌러주세요.
            </td>
          </tr>
        </tbody>
      </table>
      <div class="btn">
        <router-link to="" class="black" @click.native="authEmailComfirm"
          >수정 완료</router-link
        ><router-link to="" @click.native="clear(true)" class="gray"
          >취소</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    myInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isTimer: false,
      timer: "",
      email: "",
      cellphone: null,
      authNumber: null,
    };
  },
  created() {
    //초기화
    this.$store.commit("member/updateCellphoneReset");
  },
  mounted() {
    if (this.$store.state.mypage.myInfo.sms === "y") {
      this.$refs.sms.checked = true;
    }
    if (this.$store.state.mypage.myInfo.mailing === "y") {
      this.$refs.mailing.checked = true;
    }
  },
  computed: {
    ...mapState("member", ["msg", "old_cellphone", "tryCnt", "limitCnt"]),
    ...mapState("mypage", {
      myMsg: "msg",
      myResult: "result",
      isMailAuth: "isMailAuth",
    }),
    cellphoneCk: {
      get() {
        return this.cellphone;
      },
      set(cellphone) {
        this.cellphone = cellphone.replace(/[^0-9]/g, "");
      },
    },
    authNumberCk: {
      get() {
        return this.authNumber;
      },
      set(authNumber) {
        this.authNumber = authNumber.replace(/[^0-9]/g, "");
      },
    },
    isAuthNumber: {
      get() {
        return this.$store.state.member.isAuthNumber;
      },
      set(isAuthNumber) {
        this.$store.commit("member/updateUserInfo", {
          isAuthNumber,
        });
      },
    },
  },
  methods: {
    check(type = false) {
      if (type) {
        if (!this.$refs.sms.checked) {
          this.$confirm("SMS 수신 동의를 해제 하시겠습니까?")
            .then(async () => {
              await this.$store.dispatch("mypage/consentSmsMail", {
                type: "sms",
                value: this.$refs.sms.checked,
              });
              this.$refs.sms.checked = false;
              this.$toast.default(this.myMsg);
              //this.$alert(this.myMsg);
            })
            .catch(() => {
              this.$refs.sms.checked = true;
            });
        } else {
          this.$confirm("SMS 수신 동의를 동의 하시겠습니까?")
            .then(async () => {
              await this.$store.dispatch("mypage/consentSmsMail", {
                type: "sms",
                value: this.$refs.sms.checked,
              });
              this.$refs.sms.checked = true;
              this.$toast.default(this.myMsg);
              //this.$alert(this.myMsg);
            })
            .catch(() => {
              this.$refs.sms.checked = false;
            });
        }
      } else {
        if (!this.$refs.mailing.checked) {
          this.$confirm("E-MAIL 수신 동의를 해제 하시겠습니까?")
            .then(async () => {
              await this.$store.dispatch("mypage/consentSmsMail", {
                type: "mail",
                value: this.$refs.mailing.checked,
              });
              this.$refs.mailing.checked = false;
              //this.$toast.default(this.myMsg);
              this.$alert(this.myMsg);
            })
            .catch(() => {
              this.$refs.mailing.checked = true;
            });
        } else {
          this.$confirm("E-MAIL 수신 동의를 동의 하시겠습니까?")
            .then(async () => {
              await this.$store.dispatch("mypage/consentSmsMail", {
                type: "mail",
                value: this.$refs.mailing.checked,
              });
              this.$refs.mailing.checked = true;
              this.$toast.default(this.myMsg);
            })
            .catch(() => {
              this.$refs.mailing.checked = false;
            });
        }
      }
    },
    clear(email = null) {
      if (!email) {
        this.cellphone = null;
        this.authNumber = null;
      } else {
        this.email = null;
      }
    },
    async getAuthNumber() {
      const phoenReg = /^01([0|1|6|7|8|9])([0-9]{3,4})([0-9]{4})$/;
      if (!phoenReg.test(this.cellphone)) {
        this.$toast.default("'-' 없는 휴대폰 번호 형식으로 입력해주세요.");
        this.cellphone = null;
        return false;
      }
      if (this.isAuthNumber) {
        if (this.old_cellphone === this.cellphone) {
          this.$toast.default(
            `동일한 번호로 이미 인증번호를 발송 했습니다.
          인증번호 요청시도(${this.tryCnt}/${this.limitCnt})`
          );
          return false;
        }
      }

      this.isAuthNumber = false;
      clearInterval(this.timer);
      this.isTimer = false;

      await this.$store.dispatch("member/getAuthNumber", {
        cellphone: this.cellphone,
      });
      if (this.isAuthNumber) {
        if (this.isTimer === false) {
          this.startTimer();
        }
        this.$toast.default(this.msg);
      } else {
        this.$toast.default(this.msg);
      }
    },
    async changePhoneNumber() {
      if (this.isAuthNumber === false) {
        this.$toast.default("휴대폰 번호 인증을 해주세요.");
        return false;
      }
      await this.$store.dispatch("mypage/changePhoneNumber", {
        cellphone: this.cellphone && this.cellphone.trim(),
        authNumber: this.authNumber && this.authNumber.trim(),
      });
      if (this.isAuthNumber) {
        if (this.isTimer === false) {
          this.startTimer();
        }
      }
      this.$toast.default(this.myMsg);
      if (this.myResult) {
        this.isAuthNumber = false;
        clearInterval(this.timer);
        this.clear();
      }
    },
    async authEmail() {
      const regEmail =
        /(^[_0-9a-zA-Z-]+(\.[_0-9a-zA-Z-]+)*@[0-9a-zA-Z-]+(\.[0-9a-zA-Z-]+)*$)/;

      if (!regEmail.test(this.email.trim())) {
        this.$toast.default("올바른 이메일 형식 아닙니다.");
        return false;
      }
      await this.$store.dispatch("mypage/authEmail", {
        email: this.email.trim(),
      });
      this.$toast.default(this.myMsg);
    },
    async authEmailComfirm() {
      if (!this.isMailAuth) {
        this.$toast.default("이메일 인증을 해주세요.");
        return;
      }
      await this.$store.dispatch("mypage/authEmailComfirm", {
        email: this.email.trim(),
      });
      if (this.myResult) {
        this.clear(true);
      }
      this.$toast.default(this.myMsg);
    },
    startTimer() {
      this.isTimer = true;
      const minEl = this.$refs.timer_min;
      const secEl = this.$refs.timer_sec;
      let sec = 59;
      let min = 2;
      let sec_str = "";
      this.timer = setInterval(() => {
        if (sec == 0) {
          sec = 59;
          if (min != 0) {
            min = min - 1;
          }
        } else {
          sec = sec - 1;
          sec_str = sec.toString();
          if (sec < 10) {
            sec_str = `0${sec.toString()}`;
          }
        }
        minEl.textContent = min;
        secEl.textContent = sec_str;
        if (min == 0 && sec == 0) {
          clearInterval(this.timer);
          this.isAuthNumber = false;
        }
      }, 1000);
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="scss">
.timer {
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  color: $info-txt;
  font-size: 14px;
}
#timer_min {
  float: none;
}
#timer_sec {
  float: none;
}
</style>
