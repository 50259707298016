<template>
  <div class="rn_my_wrap">
    <div class="rn_my_tit_2">
      설정<router-link to="/mypage/my_info"
        ><img src="@/assets/images/sub/back_ico.png" alt=""
      /></router-link>
    </div>
    <div class="rn_my_empty_30"></div>
    <div class="rn_my_box_19">
      <div class="top">
        <div class="box" :class="{ on: profile }">
          <div class="sect_tit" @click="open('profile')">
            내 프로필 관리하기<a href="javascript:void(0);">
              <img
                src="@/assets/images/sub/my_up_ico.png"
                alt=""
                v-if="profile"
              />
              <img src="@/assets/images/sub/my_down_ico.png" alt="" v-else />
            </a>
          </div>
          <MyInfoProfile :myInfo="myInfo" :msg="msg" :myChannel="myChannel" />
        </div>
        <div class="box" :class="{ on: privacy }">
          <div class="sect_tit" @click="open('privacy')">
            개인정보 수정하기<a href="javascript:void(0);">
              <img
                src="@/assets/images/sub/my_up_ico.png"
                alt=""
                v-if="privacy"
              />
              <img src="@/assets/images/sub/my_down_ico.png" alt="" v-else />
            </a>
          </div>
          <MyInfoPhoneEmail :myInfo="myInfo" />
        </div>
        <div class="box" :class="{ on: password }">
          <div class="sect_tit" @click="open('password')">
            비밀번호 변경하기<a href="javascript:void(0);">
              <img
                src="@/assets/images/sub/my_up_ico.png"
                alt=""
                v-if="password"
              />
              <img src="@/assets/images/sub/my_down_ico.png" alt="" v-else />
            </a>
          </div>
          <MyPassword :myInfo="myInfo" />
        </div>
        <div class="box" :class="{ on: channel }">
          <div class="sect_tit" @click="open('channel')">
            계정 및 채널관리<a href="javascript:void(0);">
              <img
                src="@/assets/images/sub/my_up_ico.png"
                alt=""
                v-if="channel"
              />
              <img src="@/assets/images/sub/my_down_ico.png" alt="" v-else />
            </a>
          </div>
          <div class="sect_cont common">
            <div class="sect_type_4">
              <ul>
                <!-- <li>채널 삭제하기</li> -->
                <li @click="goPage('reviewer')">
                  리뷰어 채널 삭제하기<router-link to=""
                    ><img src="@/assets/images/sub/my_move_ico.png" alt=""
                  /></router-link>
                </li>
                <li @click="goPage('seller')">
                  판매자 채널 삭제 신청하기<router-link to=""
                    ><img src="@/assets/images/sub/my_move_ico.png" alt=""
                  /></router-link>
                </li>
                <!-- <li>
                  리뷰 거래소 채널 삭제하기<a href="#"
                    ><img src="@/assets/images/sub/my_move_ico.png" alt=""
                  /></a>
                </li> -->
                <li @click="goPage('member')">
                  계정 삭제하기(회원탈퇴)<router-link to=""
                    ><img src="@/assets/images/sub/my_move_ico.png" alt=""
                  /></router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom">버전정보&nbsp;&nbsp;&nbsp;램 2.0</div>
    </div>
    <!---->
  </div>
</template>

<script>
import { mapState } from "vuex";
import MyInfoProfile from "@/components/mypage/myinfo/MyInfoProfile";
import MyInfoPhoneEmail from "@/components/mypage/myinfo/MyInfoPhoneEmail";
import MyPassword from "@/components/mypage/myinfo/MyPassword";
export default {
  data() {
    return {
      profile: true,
      privacy: false,
      password: false,
      channel: false,
    };
  },
  components: {
    MyInfoProfile,
    MyInfoPhoneEmail,
    MyPassword,
  },
  created() {
    //인증체크
    if (!this.checkAuth) {
      this.$toast.default("비밀번호 인증이 필요합니다.");
      this.$router.push("/mypage/auth_check");
    }
  },
  computed: {
    ...mapState("mypage", {
      result: "result",
      msg: "msg",
      myInfo: "myInfo",
      myChannel: "channel",
      checkAuth: "checkAuth",
    }),
  },
  methods: {
    open(type) {
      this.$data[type] = !this.$data[type];
    },
    goPage(type) {
      if (type === "reviewer") {
        if (this.myInfo.is_reviewer !== "Y") {
          this.$toast.default("리뷰어 채널이 존재하지 않습니다.");
          return false;
        }
        this.$router.push("/member/reviewer_withdraw");
        return false;
      }
      if (type === "seller") {
        if (this.myInfo.is_seller !== "Y") {
          this.$toast.default("판매자 채널이 존재하지 않습니다.");
          return false;
        }
        this.$router.push("/member/seller_withdraw");
        return false;
      }
      if (type === "member") {
        if (this.myInfo.is_reviewer == "Y") {
          this.$toast.default(
            "리뷰어 채널이 존재합니다. 채널 삭제 후 진행해 주세요"
          );
          return false;
        }
        if (this.myInfo.is_seller == "Y") {
          this.$toast.default(
            "판매자 채널이 존재합니다. 채널 삭제 후 진행해 주세요"
          );
          return false;
        }
        if (this.myInfo.is_exchange == "Y") {
          this.$toast.default(
            "거래소 채널이 존재합니다. 채널 삭제 후 진행해 주세요"
          );
          return false;
        }
        this.$router.push("/member/withdraw");
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.top {
  .box {
    &.on {
      background: #ffd140;
    }
  }
}
</style>
